import { getAuth } from 'firebase/auth';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import { app } from '../firebase';

import { userDataConverter } from './converters';
import { User } from '../../Models';

const db = getFirestore(app);

export const updateUser = (
    user: User, // todo migrar a user.endpoints
) =>
    updateDoc(
        doc(db, 'pharmacies', getAuth(app).currentUser!.uid).withConverter(userDataConverter),
        user,
    );
