import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import * as React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { styled } from '@mui/system'; // eslint no lo detecta. raro
import { useEffect, useState } from 'react';
import { getHealthProviders, translateHealthProviderType } from '../../services/HealthProviders';
import { User } from '../../Models';
import { updateUser } from '../../services/User';
import { SaveButton } from './SaveButton';

const GroupItems = styled('ul')({
    padding: 0,
});
const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor: 'white',
}));

export function HealthProviders({ user }: { user: User }) {
    const [changed, setChanged] = useState(false);
    const [saving, setSaving] = useState(false);
    const [selectedHPs, setSelectedHPs] = useState(user.health_providers || []);
    const healthProviders = getHealthProviders();

    const healthProviderOptions: string[] = Object.values(healthProviders)
        .sort((a, b) => a.type.localeCompare(b.type) * 1000 + a.name.localeCompare(b.name))
        .map((hp) => hp.id);

    const saveChanges = (event: React.SyntheticEvent) => {
        event.preventDefault();
        setSaving(true);
        updateUser({
            ...user,
            health_providers: selectedHPs,
        }).then(() => {
            setSaving(false);
            setChanged(false);
        });
    };
    useEffect(() => {
        if (healthProviders && selectedHPs.length) {
            selectedHPs.forEach((hp) => {
                if (!healthProviders[hp]) {
                    console.error(`No such HP ${hp}`);
                }
            });
        }
    }, [healthProviders, selectedHPs]);
    const onChange = (event: React.SyntheticEvent, value: string[] | null) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        !changed && setChanged(true);
        setSelectedHPs(value || []);
    };

    return (
        <form onSubmit={saveChanges}>
            <Autocomplete
                sx={{ mb: 1 }}
                multiple
                size="small"
                noOptionsText=""
                options={healthProviderOptions}
                getOptionLabel={(option) => healthProviders[option].name}
                disableCloseOnSelect
                groupBy={(option) => healthProviders[option].type}
                value={selectedHPs}
                onChange={onChange}
                filterSelectedOptions
                renderInput={(params) => <TextField {...params} />}
                renderGroup={(params) => (
                    <li key={params.key}>
                        <GroupHeader>{translateHealthProviderType(params.group)}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                    </li>
                )}
                renderOption={(props, option) => (
                    <li {...props} id={option}>
                        {healthProviders[option].name}
                    </li>
                )}
                renderTags={(value, getTagProps) =>
                    value
                        .filter((v) => !!healthProviders[v])
                        .map((v, i) => (
                            <Chip
                                id={v}
                                size="small"
                                label={healthProviders[v].name}
                                {...getTagProps({ index: i })}
                            />
                        ))
                }
            />
            <SaveButton saving={saving} enabled={changed} />
        </form>
    );
}
